import styled from "styled-components"

const ThirdSplash = () => {
    return (
        <StyledSplash>
            <StyledContainer>
                <StyledHeading id="roadmap">
                    Bearly A Roadmap
                </StyledHeading>
                <StyledP>Coming Soon</StyledP>
                <StyledImage src="https://good-bears-images.s3.eu-west-1.amazonaws.com/donut.png" alt="Dream" />
            </StyledContainer>
            <Spacer></Spacer>
        </StyledSplash>
    )
}

const Spacer = styled.div`
    aspect-ratio: 960/100;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('/wave-haikei-2.svg');
`

const StyledSplash = styled.div`
    background-color: #fe6770;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledHeading = styled.div`
    margin: 2em 0;
    background-color: #FFF;
    padding: 0.7rem 2.5rem;
    border-radius: 4rem;
    border: 0.3rem solid black;
    color: #fe6770;
    font-size: 2.5rem;

    @media (max-width: 630px) {
        font-size: 2rem;
    }
`

const StyledP = styled.p`
    font-family: 'Round', sans-serif;
    color: #F2EDC8;
    font-size: 4em;
    margin: 1em 0 0 0;
    -webkit-text-stroke: 2px black;

    @media (max-width: 630px) {
        font-size: 3rem;
    }
`

const StyledImage = styled.img`
    max-height: 30em;

    @media (max-width: 630px) {
        height: 100%;
        width: 100%;
    }
`

export { ThirdSplash }