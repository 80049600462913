import styled from "styled-components"

const FourthSplash = () => {
    return (
        <StyledSplash>
            <StyledContainer>
                <StyledHeading id="team">
                    The Team
                </StyledHeading>
                <StyledTeamMember>
                    <StyledImage src="/team_melissa.png" alt="team" />
                    <StyledImage src="/team_scaelam.png" alt="team" />
                </StyledTeamMember>
                <StyledTeamMember>
                    <StyledImage src="/team_stig.png" alt="team" />
                    <StyledImage src="/team_platiinum.png" alt="team" />
                </StyledTeamMember>
            </StyledContainer>
        </StyledSplash>
    )
}

const StyledSplash = styled.div`
    background-color: #F5E068;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledHeading = styled.div`
    margin: 2em 0;
    background-color: #fe6770;
    padding: 0.7rem 2.5rem;
    border-radius: 4rem;
    border: 0.3rem solid black;
    color: white;
    font-size: 2.5rem;
`

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledTeamMember = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`

const StyledImage = styled.img`
    max-width: 15em;
    width: 100%;
    margin: 1em;
`

export { FourthSplash }
