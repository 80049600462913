import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import styled from "styled-components"
import { useMatchMedia } from "../hooks/useMatchMedia"

const Nav = () => {
    const isDesktopResolution = useMatchMedia('(min-width:1160px)', true)

    return (
        <>
            <StyledNav>
                {/*<StyledNavLinks>*/}
                {/*    <StyledLink to="home" activeClass="active" offset={-50} smooth={true} spy={true} duration={500}>Home</StyledLink>*/}
                {/*    <StyledLink to="dream" activeClass="active" offset={-80} smooth={true} spy={true} duration={500}>Dream</StyledLink>*/}
                {/*    <StyledLink to="roadmap" activeClass="active" offset={-80} smooth={true} spy={true} duration={500}>Roadmap</StyledLink>*/}
                {/*    <StyledLink to="team" activeClass="active" offset={-80} smooth={true} spy={true} duration={500}>Team</StyledLink>*/}
                {/*</StyledNavLinks>*/}
                <Images>
                    <a href="https://discord.gg/VYDV25z97P" target="_blank" rel="noreferrer">
                        <StyledImage src="https://good-bears-images.s3.eu-west-1.amazonaws.com/discord_logo.png" alt="Discord" />
                    </a>
                    <a href="https://twitter.com/Bearly_an_NFT" target="_blank" rel="noreferrer">
                        <StyledImage src="https://good-bears-images.s3.eu-west-1.amazonaws.com/twitter_logo.png" alt="twitter" />
                    </a>
                </Images>
            </StyledNav>
        </>


    )
}

const StyledNav = styled.nav`
    display: flex;
    margin-left: auto;
    color: white;

    @media (max-width: 750px) {
        flex-direction: column;
    }
`

const StyledNavLinks = styled.div`
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
        margin-bottom: 2em;
    }
`

const StyledLink = styled(Link)`
    text-transform: uppercase;
    padding: 0 1em;
    font-size: 1.5em;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 0.06em;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 750px) {
        font-size: 1em;
    }
`


const Images = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1.5em;
    align-items: center;
    a {
        padding: 0 0.5em;
    }
`

const StyledImage = styled.img`
    width: 35px;
    margin-right: 0.5em;
`

export { Nav }