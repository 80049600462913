import styled from "styled-components"

const SecondSplash = () => {

    return (
        <StyledSplash>
            <StyledContainer>
                <StyledHeading id="dream">
                    The Dream
                </StyledHeading>
                <StyledInnerContainer>
                    <LeftSplash>
                        <CloudLeft src="/cloud_big.png" alt="Cloud" />
                        <TextBlock>
                            <p>
                                <RedSpan>Bearly an NFT</RedSpan> has one core concept at heart, accessibility.
                                <br />
                                A fair, transparent project built in partnership
                                with the community for the community.
                                <br />
                                Always willing to go the extra mile and being ridiculously cute while we do it.
                            </p>
                        </TextBlock>
                    </LeftSplash>
                    <RightSplash>
                        <StyledImage src="https://good-bears-images.s3.eu-west-1.amazonaws.com/img_thedream.png" alt="Dream" />
                    </RightSplash>
                </StyledInnerContainer>
            </StyledContainer>
            <Spacer></Spacer>
        </StyledSplash>
    )
}

const RedSpan = styled.span`
    background-color: #fe6770;
    color: white;
    padding: 0.1em;
    border-radius: 0.2em;
`

const TextBlock = styled.div`
    position: absolute;
`

const Spacer = styled.div`
    aspect-ratio: 960/100;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('/wave-haikei.svg');
`

const CloudLeft = styled.img`
    max-width: 34em;
    position: absolute;
    left: -6em;
    bottom: 1em;
    transition: all 500ms ease;

    @media (max-width: 755px) {
        max-width: 30em;
        left: -7em;
    }

    @media (max-width: 630px) {
        max-width: 20em;
        left: -5em;
        bottom: 3em;
    }
`

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5em;
    display: flex;
    justify-content: center;

    @media (max-width: 630px) {
        margin: 0;
        width: 100%;
    }
`

const LeftSplash = styled.div`
    margin: 0 1em 1em;
    font-size: 1.5em;
    min-width: 21em;
    min-height: 12em;
    position: relative;
    color: #fe6770;
    transition: all 500ms ease;

    @media (max-width: 630px) {
        font-size: 1.5em;
        min-width: 15em;
        margin: 0;
    }
`

const RightSplash = styled.div`
    margin: 0 1em;
`

const StyledSplash = styled.div`
    background-color: #68C7F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledHeading = styled.div`
    margin: 2em 0;
    background-color: #FF626C;
    padding: 0.7rem 2.5rem;
    border-radius: 4rem;
    border: 0.3rem solid black;
    color: white;
    font-size: 2.5rem;
`

const StyledImage = styled.img`
    max-height: 22em;
    position: relative;
    bottom: 4em;

    @media (max-width: 630px) {
        height: 100%;
        width: 100%;
        position: block;

        margin-bottom: 2em;
    }
`

export { SecondSplash }