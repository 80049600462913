import styled from "styled-components"

const Footer = () => {
    return (
        <StyledFooter>
            <StyledP>Bearly an NFT 2022</StyledP>
            <Images>
                <a href="https://discord.gg/VYDV25z97P" target="_blank" rel="noreferrer">
                    <StyledImage src="https://good-bears-images.s3.eu-west-1.amazonaws.com/discord_logo.png" alt="Discord" />
                </a>
                <a href="https://twitter.com/Bearly_an_NFT" target="_blank" rel="noreferrer">
                    <StyledImage src="https://good-bears-images.s3.eu-west-1.amazonaws.com/twitter_logo.png" alt="twitter" />
                </a>
            </Images>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: row;
    background-color: #F5E068;
    align-content: space-between;
`

const Images = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1em;
    margin-left: auto;
    a {
        padding: 0 0.5em;
    }
`

const StyledImage = styled.img`
    width: 35px;
    margin-right: 0.5em;
`

const StyledP = styled.p`
    color: white;
    margin-left: 1em;
    align-items: center;
    justify-content: center;
    display: flex;
`

export { Footer }