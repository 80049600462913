import React from "react";
import {Mint} from "./components/Mint";
import {createGlobalStyle} from "styled-components";
import {OpenSplash} from "./components/OpenSplash";
import {SecondSplash} from "./components/SecondSplash";
import {ThirdSplash} from "./components/ThirdSplash";
import {FourthSplash} from "./components/FourthSplash";
import {Footer} from "./components/Footer";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Round';
    src: url("/round.woff") format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
`

function App() {

  return (
  <>
    <GlobalStyle />
    <OpenSplash />
    {/*<SecondSplash />*/}
    {/*<ThirdSplash />*/}
    {/*<FourthSplash />*/}
    {/*<Footer />*/}
  </>
  );
}

export default App;
