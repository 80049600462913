import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  border-radius: 50px;
  border: none;
  background-color: #12B7A3;
  border: 0.3rem solid white;
  font-family: Franxurter, sans-serif;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
  padding: 0.5em 1em; 
  
  transition: all 200ms ease;

  &:hover {
      background-color: #68C7F5;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #12B7A3;
  padding: 0.8em;
  font-size: 2em;
  color: white;
  width: 1em;
  height: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid white;
  font-family: Franxurter, sans-serif;
  transition: all 200ms ease;

  &:hover {
    background-color: #68C7F5;
  }
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #68C7F5;
    padding: 3em;
    border-radius: 3em;
    width: 100%;
    height: 100%;
`


export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const Mint = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `WOW, the ${CONFIG.NFT_NAME} is yours!`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        setMintAmount(newMintAmount);
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    return (
        <StyledContainer>
            <StyledTextTitle>
                Founding Mint
            </StyledTextTitle>
            <StyledText>
                {blockchain.account !== "" &&
                    blockchain.smartContract !== null && (
                        <>Minted: {data.totalSupply} / {CONFIG.MAX_SUPPLY}</>
                    ) }
            </StyledText>
            {/*<s.TextDescription*/}
            {/*    style={{*/}
            {/*        textAlign: "center",*/}
            {/*        color: "var(--primary-text)",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    /!*<StyledLink style={{color: "white"}} target={"_blank"} href={CONFIG.SCAN_LINK}>*!/*/}
            {/*    /!*    Contract: {CONFIG.CONTRACT_ADDRESS}*!/*/}
            {/*    /!*</StyledLink>*!/*/}
            {/*</s.TextDescription>*/}
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "white" }}
                    >
                        The sale has ended.
                    </s.TextTitle>
                </>
            ) : (
                <>
                    <StyledText>
                        Cost: {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}
                    </StyledText>
                    {/*<s.TextDescription*/}
                    {/*    style={{ textAlign: "center", color: "white" }}*/}
                    {/*>*/}
                    {/*    Excluding gas fees.*/}
                    {/*</s.TextDescription>*/}
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                            <StyledText>
                                Connect to the {CONFIG.NETWORK.NAME}
                            </StyledText>
                            <StyledButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData();
                                }}
                            >
                                CONNECT
                            </StyledButton>
                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <s.TextDescription
                                        style={{
                                            textAlign: "center",
                                            color: "white",
                                        }}
                                    >
                                        {blockchain.errorMsg}
                                    </s.TextDescription>
                                </>
                            ) : null}
                        </s.Container>
                    ) : (
                        <>
                            <StyledText>
                                {feedback}
                            </StyledText>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledRoundButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmount();
                                    }}
                                >
                                    -
                                </StyledRoundButton>
                                <s.SpacerMedium />
                                <s.TextDescription
                                    style={{
                                        textAlign: "center",
                                        color: "white",
                                        "font-size": "2em"
                                    }}
                                >
                                    {mintAmount}
                                </s.TextDescription>
                                <s.SpacerMedium />
                                <StyledRoundButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount();
                                    }}
                                >
                                    +
                                </StyledRoundButton>
                            </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                    }}
                                >
                                    {claimingNft ? "BUSY" : "BUY"}
                                </StyledButton>
                            </s.Container>
                        </>
                    )}
                </>
            )}
            <s.SpacerMedium />
    </StyledContainer>
    );
}

const StyledTextTitle = styled.p`
    font-size: 4em;
    color: white;
    text-align: center;

    
    @media (max-width: 1220px) {
        font-size: 3em;
    }
    
    @media (max-width: 1025px) {
        font-size: 2em;
    }
`

const StyledText = styled.p`
    text-align: center;
    font-size: 2.7em;
    color: white;
    margin-bottom: 0.5em;
    
    @media (max-width: 1488px) {
        font-size: 2.2em;
    }
    
    @media (max-width: 1220px) {
        font-size: 2em;
    }
    
    @media (max-width: 1025px) {
        font-size: 1.5em;
    }
`

export { Mint }