import styled from "styled-components"
import { Nav } from "./Nav";
import {Mint} from "./Mint";
import {useSearchParams} from "react-router-dom";

const OpenSplash = () => {
    const [searchParams] = useSearchParams();
    const param = searchParams.get('mint')
    return (
        <StyledSplash>
            <Nav />
            <StyledContainer>
                <StyledImageContainer>
                    <img src="/left.png" alt="left bear" />
                </StyledImageContainer>
                <StyledMiddle>
                    <StyledSplashImage src="https://good-bears-images.s3.eu-west-1.amazonaws.com/main_logo.png" alt="Main Logo" />
                    <Mint />
                </StyledMiddle>
                <StyledImageContainer>
                    <img src="/right.png" alt="right bear" />
                </StyledImageContainer>
            </StyledContainer>



        </StyledSplash>

    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    margin-bottom: -5px;
    width: 100%;
`

const StyledImageContainer = styled.div`
    margin-top: auto;
    
    img {
        width: 100%;
        max-width: 70em;
    }
    
    @media (max-width: 1118px) {
        img {
            max-width: 40em;
        }
    }
`

const StyledMiddle = styled.div`
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const StyledSplash = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fe6770;
    height: 100vh;
    overflow-y:hidden;
    // background-image: url("/background.png");
    background-size: cover;
    background-position: center;
`

const StyledSplashImage = styled.img`
    max-width: 27rem;
    width: 100%;
    margin: 2rem 2rem;
    transition: all 200ms ease;

    @media (max-width: 710px) {
        max-width: 28rem;
    }

    @media (max-width: 1118px) {
        width: 100%;
        height: 100%;
    }
`

const DiscordButton = styled.div`
    background-color: #68C7F5;
    padding: 0.5rem 2rem;
    border-radius: 1.5rem;
    border: 0.3rem solid black;
    font-weight: bold;
    transition: all 200ms ease;

    &:hover {
        background-color: #F4DF72;
    }
    
`

const StyledImageGrid = styled.div`
    width: 100%;
`

const StyledImageRow = styled.div`
    width: 100%;
    display: flex;
`

const StyledImageWrapper = styled.div`
    flex: 1;
`

const StyledBottomImage = styled.img`
    width: 100%;
`

const StyledLink = styled.a`
    margin: 2rem 0;
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
    letter-spacing: 0.06em;
`

export { OpenSplash }